import { render } from "./OverViewDetails.vue?vue&type=template&id=e51aa83c&scoped=true"
import script from "./OverViewDetails.vue?vue&type=script&lang=ts"
export * from "./OverViewDetails.vue?vue&type=script&lang=ts"

import "./OverViewDetails.vue?vue&type=style&index=0&id=e51aa83c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-e51aa83c"

export default script
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpace,QTable,QTr,QTh,QTd});
