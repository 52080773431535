
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IDateRangePicker, IOverviewDetail } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import EChart from '@/components/app/EChart.vue'
import { ChartModel } from '@/models/chart-model'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: {
    CLoading,
    EChart,
    DateRangeSelectorV2,
  },
  directives: { maska },
})
export default class OverView extends mixins(AppMixin, BaseFormMixin) {
  loading = true
  friendsChart: ChartModel = new ChartModel([], [])
  usersStatistic: IOverviewDetail[] = []

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  componentKey = 1

  get seriesData() {
    return [
      {
        name: this.$t('label.app.chart_title'),
        data: this.friendsChart.yAxis,
        type: 'line',
        areaStyle: {
          color: 'rgba(92, 252, 169, 0.4)',
        },
        lineStyle: {
          width: 2,
        },
        symbol: 'circle',
        symbolSize: 7,
      },
    ]
  }

  async fetchSelectingApp() {
    this.usersStatistic = []
    if (!this.appId) {
      return
    }

    this.loading = true

    const resp = await this.$store.dispatch(ACTION_APP.OVERVIEW_DETAIL, {
      app_id: this.appId,
      is_test_mode: false,
      date_from: DatetimeUtils.formatDate(this.dateRange.startDate),
      date_to: DatetimeUtils.formatDate(this.dateRange.endDate),
    })
    if (resp) {
      // eslint-disable-next-line
      this.usersStatistic = resp['users_statistic']
    }
    this.initCharts()
    this.loading = false
  }

  initCharts(): void {
    this.friendsChart = new ChartModel([], [])
    const total = this.usersStatistic.length
    for (let index = total - 1; index >= 0; index--) {
      const item = this.usersStatistic[index]
      const xAxis = item.date_mm_dd ?? ''
      this.friendsChart.setPoint(xAxis, item.total_active_users ?? 0)
    }

    this.componentKey++
  }

  changeDateRange(value) {
    this.dateRange = value
    this.fetchSelectingApp()
  }

  @Watch('appId', { immediate: true })
  appIdChanged() {
    this.dateRange = DatetimeUtils.getDateRange('last7Days')
    this.fetchSelectingApp()
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }
}
