<template>
  <div class="row">
    <CLoading :loading="loading" />
    <q-space />
    <DateRangeSelectorV2
      v-model="dateRange"
      :disable="false"
      :display-label="true"
      @update:onChangeDateRange="changeDateRange"
    ></DateRangeSelectorV2>
  </div>

  <div class="q-pa-md q-gutter-md text-center" :key="componentKey">
    <EChart
      v-if="friendsChart"
      :titleChart="$t('label.app.chart_title')"
      :xAxisData="friendsChart.xAxis"
      :seriesData="seriesData"
      :showLegend="false"
      :chartColor="'rgba(92, 252, 169, 1)'"
      :chartBgColor="'rgba(92, 252, 169, 0.4)'"
    ></EChart>
  </div>

  <div class="q-pa-md q-gutter-md">
    <q-table
      flat
      dense
      v-if="usersStatistic"
      :rows="usersStatistic"
      row-key="date"
      bordered
      :rows-per-page-options="[10]"
      separator="cell"
      :no-data-label="$t('messages.no_data_available')"
      class="text-grey-8"
    >
      <template v-slot:header>
        <q-tr>
          <q-th style="width: 150px"></q-th>
          <q-th
            ><span class="text-bold">{{ $t('label.app.new_users') }} </span></q-th
          >
          <q-th>
            <span class="text-bold">{{ $t('label.app.blocked_users') }}</span>
          </q-th>
          <q-th
            ><span class="text-bold">{{ $t('label.app.total_active_users') }}</span>
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="date" :props="props">{{ props.row.date }} </q-td>
          <q-td key="new_users" :props="props"> {{ numberWithCommas(props.row.new_users) }} </q-td>
          <q-td key="blocked_users" :props="props"> {{ numberWithCommas(props.row.blocked_users) }} </q-td>
          <q-td key="total_active_users" :props="props"> {{ numberWithCommas(props.row.total_active_users) }}</q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script lang="ts">
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { IDateRangePicker, IOverviewDetail } from '@/utils/types'
import { ACTION_APP } from '@/store/actions'
import AppMixin from '@/components/app/mixins/AppMixin.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import { Formater } from '@/utils/formater'
import CLoading from '@/components/common/ui/CLoading.vue'
import EChart from '@/components/app/EChart.vue'
import { ChartModel } from '@/models/chart-model'
import DateRangeSelectorV2 from '@/components/common/ui/DateRangeSelectorV2.vue'
import { DatetimeUtils } from '@/utils/datetime'

@Options({
  components: {
    CLoading,
    EChart,
    DateRangeSelectorV2,
  },
  directives: { maska },
})
export default class OverView extends mixins(AppMixin, BaseFormMixin) {
  loading = true
  friendsChart: ChartModel = new ChartModel([], [])
  usersStatistic: IOverviewDetail[] = []

  dateRange: IDateRangePicker = {
    startDate: '',
    endDate: '',
  }

  componentKey = 1

  get seriesData() {
    return [
      {
        name: this.$t('label.app.chart_title'),
        data: this.friendsChart.yAxis,
        type: 'line',
        areaStyle: {
          color: 'rgba(92, 252, 169, 0.4)',
        },
        lineStyle: {
          width: 2,
        },
        symbol: 'circle',
        symbolSize: 7,
      },
    ]
  }

  async fetchSelectingApp() {
    this.usersStatistic = []
    if (!this.appId) {
      return
    }

    this.loading = true

    const resp = await this.$store.dispatch(ACTION_APP.OVERVIEW_DETAIL, {
      app_id: this.appId,
      is_test_mode: false,
      date_from: DatetimeUtils.formatDate(this.dateRange.startDate),
      date_to: DatetimeUtils.formatDate(this.dateRange.endDate),
    })
    if (resp) {
      // eslint-disable-next-line
      this.usersStatistic = resp['users_statistic']
    }
    this.initCharts()
    this.loading = false
  }

  initCharts(): void {
    this.friendsChart = new ChartModel([], [])
    const total = this.usersStatistic.length
    for (let index = total - 1; index >= 0; index--) {
      const item = this.usersStatistic[index]
      const xAxis = item.date_mm_dd ?? ''
      this.friendsChart.setPoint(xAxis, item.total_active_users ?? 0)
    }

    this.componentKey++
  }

  changeDateRange(value) {
    this.dateRange = value
    this.fetchSelectingApp()
  }

  @Watch('appId', { immediate: true })
  appIdChanged() {
    this.dateRange = DatetimeUtils.getDateRange('last7Days')
    this.fetchSelectingApp()
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }
}
</script>

<style lang="scss" scoped>
.my-card {
  width: 100%;
  min-height: 190px;
  line-height: 3rem;
}
</style>
